import select from '@sparkle/js/fn/select/select';
import selectAll from '@sparkle/js/fn/select/selectAll';
import each from '@sparkle/js/helpers/collection/each';
import setProps from '@sparkle/js/helpers/object/setProps';
import DomManager from '@sparkle/js/utilities/DomManager';
import EventsManager from '@sparkle/js/utilities/EventsManager';
import ScrollVibe from '@sparkle/js/utilities/ScrollVibe';

/*
 * IndexWelcome functionalities
 *
 * @author Christophe Meade
 * @copyright 2021-present INBW
 *
 * @param {Node} el
 * @param {Object} options
 */
const IndexWelcome = function (el, options) {
    const that = this;

    // Options
    setProps(options, {
        selector: IndexWelcome.SELECTOR
    });

    // Global variables
    that.el = el;
    that.options = options;
    that.events = new EventsManager();
    that.vibes = [];

    // DOM
    that.dom = new DomManager();
    that.dom.set({
        title: () => select(`${options.selector}__title`, el),
        description: () => select(`${options.selector}__description`, el),
        cta: () => select(`${options.selector}__cta`, el),
        lis: () => selectAll(`${options.selector}__li`, el)
    });

    // Init
    that.init();
};

/**
 * Init
 */
IndexWelcome.prototype.init = async function () {
    const that = this;

    // Fct - Init
    const init = async () => {
        return;
    };

    // Fct - Vibe
    const vibe = async () => {

        that.vibes.push(new ScrollVibe(that.el, {
            start: 'top 65%',
            do: [

                // Title
                {
                    trigger: that.dom.$('title'),
                    target: that.dom.$('title'),
                    reverse: false,
                    event: {
                        on: 'handleReveal'
                    }
                },

                // Description
                {
                    trigger: that.dom.$('title'),
                    target: that.dom.$('description'),
                    reverse: false,
                    tween: {
                        y: {
                            speed: 500,
                            from: 30,
                            to: 0,
                            ease: 'power3.out',
                            delay: 300
                        },
                        opacity: {
                            speed: 500,
                            from: 0,
                            to: 1,
                            ease: 'power1.out',
                            delay: 300
                        }
                    }
                },
                {
                    trigger: that.dom.$('title'),
                    target: that.dom.$('cta'),
                    reverse: false,
                    tween: {
                        x: {
                            speed: 500,
                            from: 40,
                            to: 0,
                            ease: 'power3.out',
                            delay: 500
                        },
                        opacity: {
                            speed: 500,
                            from: 0,
                            to: 1,
                            ease: 'power1.out',
                            delay: 500
                        }
                    }
                }
            ]
        }));

        each(that.dom.$('lis'), ($li, i) => {
            that.vibes.push(new ScrollVibe(that.el, {
                start: 'top 70%',
                do: {
                    trigger: that.dom.$('title'),
                    target: $li,
                    reverse: false,
                    tween: {
                        x: {
                            speed: 500,
                            from: 25,
                            to: 0,
                            ease: 'power3.out',
                            delay: 700 + i * 100
                        },
                        opacity: {
                            speed: 500,
                            from: 0,
                            to: 1,
                            ease: 'power1.out',
                            delay: 700 + i * 100
                        }
                    }
                }
            }));
        });

        return;
    };

    // Init & Proceed
    await init();
    vibe();
};

/**
 * Destroy
 */
IndexWelcome.prototype.destroy = function () {
    this.dom.destroy();
    this.events.destroy();
    each(this.vibes, vibe => {
        vibe.destroy();
    });
};

/**
 * Constants
 */
IndexWelcome.SELECTOR = '.js-indexWelcome';

export default IndexWelcome;

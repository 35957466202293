import EmblaCarousel from 'embla-carousel';
import keyboardJS from 'keyboardjs';
import attrOption from './.internal/options/attrOption';
import getAttrOption from './.internal/options/getAttrOption';
import DomManager from './DomManager';
import EventsManager from './EventsManager';
import WheelEvents from './WheelEvents';
import hasClass from '../fn/attributes/hasClass';
import selectAll from '../fn/select/selectAll';
import each from '../helpers/collection/each';
import isNull from '../helpers/lang/isNull';
import parseBool from '../helpers/lang/parseBool';
import has from '../helpers/object/has';
import set from '../helpers/object/set';
import setProps from '../helpers/object/setProps';
import random from '../helpers/string/random';
import imageLoaded from '../methods/utils/imageLoaded';
import sleep from '../methods/utils/sleep';

/*
 * Carousel is the most accurate touch carousel. It is intended to be used in mobile
 * websites, mobile web apps.
 *
 * Powered by Embla Carousel (https://www.embla-carousel.com)
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node} el
 * @param {Object} options
 */
const Carousel = function (el, options) {
    const that = this;

    options = options || {};

    // Options
    setProps(options, {
        selector: Carousel.SELECTOR,

        // Settings
        direction: Carousel.DIRECTION,
        breakpoints: Carousel.BREAKPOINTS,
        loop: Carousel.LOOP,
        touch: Carousel.TOUCH,
        slide: Carousel.SLIDE,
        keyboard: Carousel.KEYBOARD,
        wheel: Carousel.WHEEL,
        align: Carousel.ALIGN,
        free: Carousel.FREE,
        start: Carousel.START,
        smart: Carousel.SMART,
        group: Carousel.GROUP,
        speed: Carousel.SPEED,
        autoplay: Carousel.AUTOPLAY,
        autoplayDelay: Carousel.AUTOPLAY_DELAY,
        autoplayPauseHover: Carousel.AUTOPLAY_PAUSE_HOVER
    });

    // Settings
    options = attrOption(options, el, ['direction', 'loop', 'slide', 'touch', 'keyboard', 'smart', 'wheel', 'align', 'free', 'start', 'group', 'speed', 'autoplay', 'autoplayDelay'], options.selector);
    options.loop = parseBool(options.loop);
    options.slide = parseBool(options.slide);
    options.touch = parseBool(options.touch);
    options.free = parseBool(options.free);
    options.keyboard = parseBool(options.keyboard);
    options.wheel = parseBool(options.wheel);
    options.autoplay = parseBool(options.autoplay);
    options.smart = parseBool(options.smart);
    options.autoplayPauseHover = parseBool(options.autoplayPauseHover);
    options.speed = parseInt(options.speed);

    // Modifiers
    that.modifiers = {
        vSelected: 'v--selected',
        vDraggable: 'v--draggable',
        vDragging: 'v--dragging',
        vStart: 'v--start'
    };

    // Global variables
    that.el = el;
    that.options = options;
    that.events = new EventsManager();

    // DOM
    that.dom = new DomManager();
    that.dom.set({
        // clicks: () => selectAll(`${options.selector}__click`, el),
        slides: () => selectAll(`${options.selector}__slide`, el)
    });

    // Init
    that.init();
};

/**
 * Init
 */
Carousel.prototype.init = function () {
    const that = this;

    // Autoplay
    that.timer = null;
    that.autoplay = that.options.autoplay;
    that.autoplaying = true;

    // Parse slides and check if one of them is set as the starting index
    each(that.dom.$('slides'), ($slide, i) => {
        if (hasClass($slide, that.modifiers.vStart)) {
            that.options.start = i;
            return;
        }
    });

    // Options
    const options = {
        axis: that.options.direction === 'horizontal' ? 'x' : 'y',
        loop: that.options.loop,
        align: that.options.align,
        draggable: that.options.touch,
        dragFree: that.options.free,
        containScroll: that.options.smart ? 'trimSnaps' : 'keepSnaps',
        skipSnaps: true,
        startIndex: that.options.start,
        slidesToScroll: that.options.group,
        duration: that.options.speed,
        selectedClass: that.modifiers.vSelected,
        draggableClass: that.modifiers.vDraggable,
        draggingClass: that.modifiers.vDragging,
        active: false,
        breakpoints: {}
    };

    // Define active/inactive breakpoints
    each(this.options.breakpoints, (isActive, breakpoint) => {
        set(options.breakpoints, breakpoint, { active: parseBool(isActive) })
    });

    // Create Embla Carousel
    that.embla = EmblaCarousel(that.el, options);

    // Listeners - Keyboard
    if (that.options.keyboard) {
        that.listenKeyboard();
    }

    // Listeners - Mouse Wheel
    if (that.options.wheel) {
        that.listenMouseWheel();
    }

    // Listeners - Slides
    that.listenSlides();

    // Listeners - Mouse
    that.listenMouse();

    // Fct - onReady
    const onReady = () => {
        that.events.trigger(that.el, 'onReady');
    };

    // Fct - onSelect
    const onSelect = () => {
        that.events.trigger(that.el, 'onSelect', {
            index: that.embla.selectedScrollSnap(),
            slides: that.embla.slideNodes().length,
            previous: that.embla.canScrollPrev(),
            next: that.embla.canScrollNext(),
            el: that.embla.slideNodes()[that.embla.selectedScrollSnap()]
        });
    };

    // Fct - onSettle
    const onSettle = () => {
        that.events.trigger(that.el, 'onSettle', {
            index: that.embla.selectedScrollSnap(),
            slides: that.embla.slideNodes().length,
            previous: that.embla.canScrollPrev(),
            next: that.embla.canScrollNext(),
            progress: that.embla.scrollProgress()
        });
    };

    // Embla Listener - Init
    that.embla.on('init', () => {

        // Trigger Events
        onReady();
        onSelect();

        // Play
        that.play();
    });

    // Embla Listener - Select
    that.embla.on('select', () => {

        // Trigger Event
        onSelect();

        // Pause
        that.pause();
    });

    // Embla Listener - Settle
    that.embla.on('settle', () => {

        // Trigger Event
        onSettle();

        // Play
        that.play();
    });

    // Embla Listener - Settle
    that.embla.on('resize', async () => {
        that.events.trigger(that.el, 'onResize');
    });

    // Listener - Previous / Handle
    that.events.on(that.el, 'handlePrevious', () => {
        that.embla.scrollPrev(!that.options.slide);
    });

    // Listener - Next / Handle
    that.events.on(that.el, 'handleNext', () => {
        that.embla.scrollNext(!that.options.slide);
    });

    // Listener - Go / Handle
    that.events.on(that.el, 'handleGo', e => {
        that.embla.scrollTo(e.detail.index);
    });

    // Listener - Jump / Handle
    that.events.on(that.el, 'handleJump', e => {
        that.embla.scrollTo(e.detail.index, true);
    });

    // Listener - Next Loop / Handle
    that.events.on(that.el, 'handleNextLoop', () => {
        if (that.embla.canScrollNext()) {
            that.embla.scrollNext();
        } else {
            that.embla.scrollTo(0);
        }
    });

    // Listener - Mouse Over / Handle
    that.events.on(that.el, 'handleMouseover', e => {
        if (that.options.autoplayPauseHover) {
            that.autoplaying = false;
            that.pause();
        }
    });

    // Listener - Refresh / Handle
    that.events.on(that.el, 'handleRefresh', e => {
        if (has(e.detail, 'index')) {
            options.startIndex = e.detail.index;
        } else {
            options.startIndex = that.embla.selectedScrollSnap();
        }

        that.embla.reInit(options);

        // Trigger Event
        onSelect();
    });

    // Listener - Mouse Out / Handle
    that.events.on(that.el, 'handleMouseout', e => {
        if (that.options.autoplayPauseHover) {
            that.autoplaying = true;
            that.play();
        }
    });
};

/**
 * Listen Slides
 */
Carousel.prototype.listenSlides = function () {
    const that = this;

    // Parse slides and refresh the carousel once the images have loaded if
    // their size is set as variable
    each(that.dom.$('slides'), async el => {
        if (parseBool(getAttrOption(el, 'variable', that.options.selector, false))) {
            await imageLoaded(el);
            await sleep(15);
            that.events.trigger(that.el, 'handleRefresh');
        }
    });
};

/**
 * Listen Mouse
 */
Carousel.prototype.listenMouse = function () {
    const that = this;

    // Listener - Mouseenter
    that.events.on(that.el, 'mouseenter', () => {
        that.events.trigger(that.el, 'handleMouseover');
    });

    // Listener - Mouseleave
    that.events.on(that.el, 'mouseleave', () => {
        that.events.trigger(that.el, 'handleMouseout');
    });
};

/**
 * Listen Mouse Wheel
 */
Carousel.prototype.listenMouseWheel = function () {
    const that = this;

    // Start listening events
    that.wheelEvents = new WheelEvents(that.el, { axis: 'x' });

    // Listener - Right with Intent
    that.events.on(that.el, 'onWheelRightIntent', () => {
        that.events.trigger(that.el, 'handlePrevious');
    });

    // Listener - Left with Intent
    that.events.on(that.el, 'onWheelLeftIntent', () => {
        that.events.trigger(that.el, 'handleNext');
    });
};

/**
 * Listen Keyboard
 */
Carousel.prototype.listenKeyboard = function () {
    const that = this;

    const keyboardContext = random(10, 'alphabetic');

    keyboardJS.withContext(keyboardContext, () => {

        keyboardJS

            // Left
            .on('left', () => {
                that.events.trigger(that.el, 'handlePrevious');
            })

            // Right
            .on('right', () => {
                that.events.trigger(that.el, 'handleNext');
            });
    });

    that.events.on(that.el, 'handleMouseover', () => {
        keyboardJS.setContext(keyboardContext);
    });

    that.events.on(that.el, 'handleMouseout', () => {
        keyboardJS.setContext('');
    });
};

/**
 * Autoplay - Play
 */
Carousel.prototype.play = function () {
    const that = this;

    if (that.autoplay && that.autoplaying) {
        that.timer = setTimeout(() => {
            that.events.trigger(that.el, 'handleNextLoop');
        }, that.options.autoplayDelay);
    }
};

/**
 * Autoplay - Pause
 */
Carousel.prototype.pause = function () {
    const that = this;

    if (!isNull(that.timer)) {
        clearTimeout(that.timer);
    }
};

/**
 * Autoplay - Stop
 */
Carousel.prototype.stop = function () {
    this.pause();
    this.autoplay = false;
};

/**
 * Destroy
 */
Carousel.prototype.destroy = function () {
    this.dom.destroy();
    this.evenement.destroy();
    this.embla.destroy();
    if (this.wheelEvents) {
        this.wheelEvents.destroy();
    }
};

/**
 * Constants
 */
Carousel.SELECTOR = '.js-carousel';
Carousel.DIRECTION = 'horizontal'; // horizontal, vertical
Carousel.BREAKPOINTS = {'all': true};
Carousel.LOOP = false;
Carousel.TOUCH = true;
Carousel.KEYBOARD = true;
Carousel.WHEEL = true;
Carousel.SLIDE = true;
Carousel.ALIGN = 'start'; // start, center, end
Carousel.FREE = false;
Carousel.START = 0;
Carousel.GROUP = 1; // # of slides to scroll
Carousel.SPEED = 25;
Carousel.SMART = true;
Carousel.AUTOPLAY = false;
Carousel.AUTOPLAY_DELAY = 5000;
Carousel.AUTOPLAY_PAUSE_HOVER = true;

export default Carousel;

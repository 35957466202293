import FontFaceObserver from 'fontfaceobserver';
import addClass from '@sparkle/js/fn/attributes/addClass';
import hasClass from '@sparkle/js/fn/attributes/hasClass';
import hide from '@sparkle/js/fn/attributes/hide';
import removeClass from '@sparkle/js/fn/attributes/removeClass';
import show from '@sparkle/js/fn/attributes/show';
import select from '@sparkle/js/fn/select/select';
import selectAll from '@sparkle/js/fn/select/selectAll';
import offset from '@sparkle/js/fn/style/offset';
import outerHeight from '@sparkle/js/fn/style/outerHeight';
import outerWidth from '@sparkle/js/fn/style/outerWidth';
import each from '@sparkle/js/helpers/collection/each';
import parseBool from '@sparkle/js/helpers/lang/parseBool';
import setProps from '@sparkle/js/helpers/object/setProps';
import className from '@sparkle/js/helpers/utils/className';
import imageLoaded from '@sparkle/js/methods/utils/imageLoaded';
import sleep from '@sparkle/js/methods/utils/sleep';
import attrOption from '@sparkle/js/utilities/.internal/options/attrOption';
import getAttrOption from '@sparkle/js/utilities/.internal/options/getAttrOption';
import EventsManager from '@sparkle/js/utilities/EventsManager';
import Morph from '@sparkle/js/utilities/Morph';
import ScrollVibe from '@sparkle/js/utilities/ScrollVibe';
import Tweener from '@sparkle/js/utilities/Tweener';

/*
 * IndexHero functionalities
 *
 * @author Christophe Meade
 * @copyright 2021-present INBW
 *
 * @param {Node} el
 * @param {Object} options
 */
const IndexHero = function (el, options) {
    const that = this;

    // Options - Selector
    setProps(options, {
        selector: IndexHero.SELECTOR,

        // Options
        autostart: IndexHero.AUTOSTART,
        speedSlideshow: IndexHero.SPEED_SLIDESHOW
    });

    // Set Options from Attr
    options = attrOption(options, el, ['autostart'], options.selector);

    // Boolean values
    options.autostart = parseBool(options.autostart);

    // Selectors
    const selectors = {

        // Statuses
        vInit: '.v--init',
        vLoaded: '.v--loaded',

        // Lazy Images
        lazy: '.js-lazy',
        lazyPreload: '.js-lazy--preload',

        // Elements
        loader: `${options.selector}__loader`,

        txt: `${options.selector}__txt`,
        txtScroll: `${options.selector}__txt-scroll`,
        txtWrapper: `${options.selector}__txt-wrapper`,

        media: `${options.selector}__media`,
        mediaSlideshow: `${options.selector}__media-slideshow`,
        mediaSlideshowSlide: `${options.selector}__media-slideshow-slide`,
        mediaOverlay: `${options.selector}__media-overlay`,

        title: `${options.selector}__title`,
        titleLine: `${options.selector}__title-line`,

        headline: `${options.selector}__headline`,
        headlineTxt: `${options.selector}__headline-txt`,

        // App
        header: '.js-header'
    };

    // Element, Options, Selectors & Events
    that.el = el;
    that.options = options;
    that.selectors = selectors;
    that.events = new EventsManager();
    that.vibes = [];

    // Init
    that.init();
};

/**
 * Init
 */
IndexHero.prototype.init = function () {
    const that = this;

    // Listener - Show
    that.events.once(that.el, 'handleShow', () => {
        that.show();
    });

    // Autostart
    if (that.options.autostart) {
        that.events.on(that.el, 'onShow', () => {
            that.initVibe();
            that.initSlideshow();
        });

        // Vibes including pins must be initialized on page load, otherwise scroll
        // problems occur
        that.initVibePin();
        that.events.trigger(that.el, 'handleShow');
    }
};

/**
 * Show
 */
IndexHero.prototype.show = async function () {
    const that = this;

    // Elements
    const elLoader = select(that.selectors.loader, that.el);

    const elMedia = select(that.selectors.media, that.el);
    const elMediaSlideshow = select(that.selectors.mediaSlideshow, elMedia);
    const elMediaSlideshowSlide = select(that.selectors.mediaSlideshowSlide, elMediaSlideshow);
    const elMediaOverlay = select(that.selectors.mediaOverlay, elMedia);

    const elTxt = select(that.selectors.txt, that.el);
    const elTxtScroll = select(that.selectors.txtScroll, elTxt);
    const elTxtScrollHandleAnimation = select('#scroller-handle-animation', elTxtScroll);
    const elTxtScrollHandleFlat = select('#scroller-handle-flat', elTxtScroll);
    const elTxtScrollHandleArrow = select('#scroller-handle-arrow', elTxtScroll);

    const elTitle = select(that.selectors.title, elTxt);
    const elTitleLines = selectAll(that.selectors.titleLine, elTitle);
    const elHeadline = select(that.selectors.headline, elTxt);
    const elHeadlineTxt = select(that.selectors.headlineTxt, elHeadline);

    // Classes
    const classInit = className(that.selectors.vInit);
    const classLoaded = className(that.selectors.vLoaded);
    const classLazy = className(that.selectors.lazy);
    const classLazyPreload = className(that.selectors.lazyPreload);

    // Fct - Init
    const init = async () => {
        Tweener.set(elMediaOverlay, { scaleX: 0, x: '50%' });
        return;
    };

    // Fct - Loaded Fonts
    const loadedFonts = async () => {

        // Font Observers
        const observers = [];
        observers.push(new FontFaceObserver('graphik').load(null, 5000));
        observers.push(new FontFaceObserver('cardo').load(null, 5000));

        // Fonts Loaded
        await Promise.all(observers);

        return;
    };

    // Fct - Loader -> Hide
    const loaderHide = async () => {
        hide(elLoader);
        return;
    };

    // Fct - Load Img
    const loadImg = async el => {
        await new Promise(resolve => {
            const elImg = select('img', el);
            imageLoaded(elImg).then(() => {
                resolve();
            });
            addClass(elImg, [classLazy, classLazyPreload]);
        });
        return;
    };

    // Fct - Loaded
    const loaded = async () => {
        await Promise.all([loadedFonts(), imageLoaded(elMediaSlideshowSlide)]);
        show(elMediaSlideshowSlide);
        addClass(elMediaSlideshowSlide, classLoaded);
        return;
    };

    // Fct - Txt -> Show
    const txtShow = async () => {

        // Overlay
        await new Tweener({ speed: 600, ease: 'power2.inOut' })
            .add(elMediaOverlay, {
                scaleX: { to: 1 },
                x: { to: 0 }
            })
            .play();

        // Txt
        const tween = new Tweener({ speed: 1000, ease: 'power3.out' });
        each(elTitleLines, (el, i) => {
            tween.add(el, {
                x: { from: '-100%', to: 0, delay: 150 * i }
            });
        });
        await tween
            .add(elHeadlineTxt, {
                y: { from: '100%', to: 0, speed: 750, ease: 'power2.inOut' }
            })
            .onStart(() => {
                Tweener.set([elTitle, elHeadline, elTxt], { opacity: 1 });
            })
            .play();

        // Scroll
        Morph.set(elTxtScrollHandleAnimation, elTxtScrollHandleFlat);
        await new Tweener({ speed: 300, ease: 'power2.out' })
            .add(elTxtScrollHandleAnimation, {
                scaleX: { from: 0, to: 1 },
            })
            .onStart(() => {
                show(elTxtScroll);
            })
            .play();

        await new Morph({ speed: 300, ease: 'sine.inOut' })
            .add(elTxtScrollHandleAnimation, {
                to: elTxtScrollHandleArrow
            })
            .play();

        return;
    };

    // Fct - Media -> Show
    const mediaShow = async () => {
        const elSlide = elMediaSlideshowSlide;
        that.events.once(elSlide, 'onReveal', () => {
            return;
        });
        await sleep(25);
        that.events.trigger(elSlide, 'handleReveal');
        Tweener.set(elMedia, { opacity: 1 });
    };

    // Fct - Done
    const done = async () => {
        that.events.trigger(that.el, 'onShow');
        return;
    };

    // Init & proceed
    await init();
    await loaded();
    await loaderHide();
    await mediaShow();
    await txtShow();
    done();
};

/**
 * Init Vibe Pin
 */
IndexHero.prototype.initVibePin = async function () {
    const that = this;

    // Elements
    const elMedia = select(that.selectors.media, that.el);

    // Fct - Init
    const init = async () => {
        return;
    };

    // Fct - Vibe
    const vibe = async () => {

        that.vibes.push(new ScrollVibe(elMedia, {
            do: [

                // Pin Media & Text
                {
                    trigger: elMedia,
                    start: 'top top',
                    end: 'bottom 75%',
                    pin: true,
                    pinFocus: true
                }
            ]
        }));

        ScrollVibe.refresh();

        return;
    };

    // Init & Proceed
    await init();
    vibe();
};

/**
 * Init Vibe
 */
IndexHero.prototype.initVibe = async function () {
    const that = this;

    // Elements
    const elMedia = select(that.selectors.media, that.el);

    const elMediaSlideshow = select(that.selectors.mediaSlideshow, elMedia);
    const elMediaOverlay = select(that.selectors.mediaOverlay, elMedia);

    const elTxt = select(that.selectors.txt, that.el);
    const elTxtScroll = select(that.selectors.txtScroll, elTxt);
    const elTxtWrapper = select(that.selectors.txtWrapper, elTxt);

    const elTitle = select(that.selectors.title, elTxt);
    const elTitleLines = selectAll(that.selectors.titleLine, elTitle);
    const elHeadline = select(that.selectors.headline, elTxt);
    const elHeadlineTxt = select(that.selectors.headlineTxt, elHeadline);

    // Fct - Init
    const init = async () => {
        return;
    };

    // Fct - Txt -> Hide
    const txtHide = async () => {
        const tween = new Tweener({ speed: 800, ease: 'power3.out' });
        each(elTitleLines, (el, i) => {
            tween.add(el, {
                y: { to: '100%', delay: 50 * i }
            });
        });
        await tween
            .add(elHeadlineTxt, {
                y: { to: '100%' }
            })
            .add(elTxtScroll, {
                opacity: { to: 0 }
            })
            .play();

        hide(elTxtScroll);

        return;
    };

    // Fct - Txt -> Show
    const txtShow = async () => {
        const tween = new Tweener({ speed: 1000, ease: 'power3.out' });
        each(elTitleLines, (el, i) => {
            tween.add(el, {
                y: { to: 0, delay: 50 * i }
            });
        });
        await tween
            .add(elHeadlineTxt, {
                y: { to: 0 }
            })
            .add(elTxtScroll, {
                opacity: { to: 1 }
            })
            .onStart(() => {
                show(elTxtScroll);
            })
            .play();

        return;
    };

    // Fct - Vibe
    const vibe = async () => {

        that.vibes.push(new ScrollVibe(elMedia, {
            do: [

                // Hide/Show Text
                {
                    trigger: elMediaSlideshow,
                    start: 'top -5%',
                    fct: {
                        on: () => {
                            txtHide();
                        },
                        off: () => {
                            txtShow();
                        }
                    }
                },

                // Parallax Image
                {
                    trigger: elMediaSlideshow,
                    target: elMediaOverlay,
                    start: 'top top',
                    end: 'bottom 25%',
                    parallax: {
                        scaleX: {
                            to: 0,
                            ease: 'power1.inOut'
                        },
                        x: {
                            to: '50%',
                            ease: 'power1.inOut'
                        }
                    }
                },
                {
                    trigger: elMediaSlideshow,
                    target: elMediaSlideshow,
                    start: 'bottom 70%',
                    duration: '70%',
                    parallax: {
                        y: {
                            to: '20%',
                            ease: 'power0.in'
                        }
                    }
                },

                // Play/Pause Slideshow
                {
                    trigger: elMediaSlideshow,
                    target: that.el,
                    start: 'bottom 50%',
                    event: {
                        on: 'handlePause',
                        off: 'handlePlay'
                    }
                },

                // Pin Txt Wrapper
                {
                    trigger: elMedia,
                    start: 'top top',
                    end: 'bottom 75%',
                    pin: true,
                    pinTarget: elTxtWrapper
                },

                // Pin Scroll
                {

                    trigger: elMedia,
                    start: 'top top',
                    end: 'bottom 75%',
                    pin: true,
                    pinTarget: elTxtScroll
                }
            ]
        }));

        return;
    };

    // Init & Proceed
    await init();
    vibe();
};

/**
 * Init Slideshow
 */
IndexHero.prototype.initSlideshow = async function () {
    const that = this;

    // Elements
    const elMedia = select(that.selectors.media, that.el);
    const elMediaSlideshow = select(that.selectors.mediaSlideshow, elMedia);
    const elMediaSlideshowSlides = selectAll(that.selectors.mediaSlideshowSlide, elMediaSlideshow);

    const elHeader = select(that.selectors.header);

    // Classes
    const classLoaded = className(that.selectors.vLoaded);
    const classLazy = className(that.selectors.lazy);
    const classLazyPreload = className(that.selectors.lazyPreload);

    // Variables
    let indexCurrent = 0;
    let isPaused = false;
    let timeout;

    // Fct - Init
    const init = async () => {
        addClass(elMediaSlideshowSlides[0], classLoaded);
        return;
    };

    // Fct - Slide -> Load
    const slideLoad = async el => {
        await new Promise(resolve => {
            const elImg = select('img', el);
            if (hasClass(el, classLoaded)) {
                resolve();
            } else {
                imageLoaded(elImg).then(() => {
                    addClass(el, classLoaded);
                    resolve();
                });
                addClass(elImg, [classLazy, classLazyPreload]);
            }
        });
        return;
    };

    // Fct - Slide -> Swap
    const slideSwap = async (elCurrent, elNext) => {

        // Move current item at the end in order to be on top of the others
        elCurrent.parentNode.appendChild(elCurrent);

        if (getAttrOption(elNext, 'theme', that.options.selector, 'light') === 'dark') {
            that.events.trigger(elHeader, 'handleDarkMode');
        } else {
            that.events.trigger(elHeader, 'handleLightMode');
        }

        // Show Animation
        await new Tweener({ speed: 4000, ease: 'power1.inOut' })
            .add(elCurrent, {
                opacity: { to: 0 },
                scaleX: { to: 1.05, ease: 'power1.inOut' },
                scaleY: { to: 1.05, ease: 'power1.out' }
            })
            .add(elNext, {
                scaleX: { from: 1.05, to: 1, ease: 'power1.out' },
                scaleY: { from: 1.05, to: 1, ease: 'power1.inOut' }
            })
            .onStart(() => {
                Tweener.set(elNext, { opacity: 1 });
                show(elNext);
            })
            .play();

        hide(elCurrent);

        return;
    };

    // Fct - Slide -> Next
    const slideNext = async () => {
        const indexNext = indexCurrent < elMediaSlideshowSlides.length - 1 ? indexCurrent + 1 : 0;
        const elSlideCurrent = elMediaSlideshowSlides[indexCurrent];
        const elSlideNext = elMediaSlideshowSlides[indexNext];
        await slideLoad(elSlideNext);
        await slideSwap(elSlideCurrent, elSlideNext);
        indexCurrent = indexNext;
        return;
    };

    // Listener - Pause
    that.events.on(that.el, 'handlePause', () => {
        isPaused = true;
        clearTimeout(timeout);
    });

    // Listener - Play
    that.events.on(that.el, 'handlePlay', () => {
        isPaused = false;
        clearTimeout(timeout);
        timeout = setTimeout(async () => {
            await slideNext();
            if (!isPaused) {
                that.events.trigger(that.el, 'handlePlay');
            }
        }, that.options.speedSlideshow);
    });

    // Init & proceed
    await init();
    that.events.trigger(that.el, 'handlePlay');
};

/**
 * Destroy
 */
IndexHero.prototype.destroy = function () {
    const that = this;

    that.events.destroy();
    each(that.vibes, vibe => {
        vibe.destroy();
    });
};

/**
 * Constants
 */
IndexHero.SELECTOR = '.js-indexHero';
IndexHero.SPEED_SLIDESHOW = 7500;

export default IndexHero;

import select from './select';

/*
 * Select matching parent node from the DOM and return it.
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node|NodeList|string} node
 *
 * @returns {Node|null}
 */
export default function (node) {

    // Select matching node
    node = select(node);

    // Node provided
    if (node) {

        let elParent = node.parentElement;
        while (elParent) {
            const { overflow } = window.getComputedStyle(elParent);
            if (overflow.split(' ').every(o => o === 'auto' || o === 'scroll')) {
                return elParent;
            }
            elParent = elParent.parentElement;
        }

        return document.documentElement;
    }

    return null;
}

import getAttr from '@sparkle/js/fn/attributes/getAttr';
import setAttr from '@sparkle/js/fn/attributes/setAttr';
import outerHeight from '@sparkle/js/fn/style/outerHeight';
import outerWidth from '@sparkle/js/fn/style/outerWidth';
import each from '@sparkle/js/helpers/collection/each';
import debounce from '@sparkle/js/helpers/function/debounce';
import selectAll from '@sparkle/js/fn/select/selectAll';
import setProps from '@sparkle/js/helpers/object/setProps';
import sleep from '@sparkle/js/methods/utils/sleep';
import EventsManager from '@sparkle/js/utilities/EventsManager';
import ScrollVibe from '@sparkle/js/utilities/ScrollVibe';

/*
 * Zeus functionalities
 *
 * @author Christophe Meade
 * @copyright 2021-present INBW
 *
 * @param {Node} el
 * @param {Object} options
 */
const Zeus = function (el, options) {
    const that = this;

    // Options
    setProps(options, {
        selector: Zeus.SELECTOR
    });

    // Selectors
    that.selectors = {
        carousel: '.js-carousel'
    };

    // Global variables
    that.el = el;
    that.options = options;
    that.events = new EventsManager();

    // Init
    that.init();
};

/**
 * Init
 */
Zeus.prototype.init = async function () {
    const that = this;

    // Ensure that all ScrollTriggers and Carousels are correctly refreshed once the
    // DOM is fully loaded
    await sleep(50);

    ScrollVibe.refresh();

    // Refresh carousels
    each(selectAll(that.selectors.carousel, that.el), el => {
        that.events.trigger(el, 'handleRefresh');
    });

    // ScrollVibe is set not to listen to window resize events because it
    // creates problems on mobile devices. Therefore a resize listener takes
    // care of that task
    let width = outerWidth(that.el);
    let height = outerHeight(that.el);
    that.events.on(that.el, 'resize', debounce(() => {
        const newWidth = outerWidth(that.el);
        const newHeight = outerHeight(that.el);
        if (outerWidth(that.el) !== width || outerHeight(that.el) !== height) {
            width = newWidth;
            height = newHeight;
            if (!ScrollVibe.isScrolling()) {
                ScrollVibe.refresh();
            }
        }
    }, 250, false));
};

/**
 * Destroy
 */
Zeus.prototype.destroy = function () {
    this.events.destroy();
};

/**
 * Constants
 */
Zeus.SELECTOR = '.js-zeus';

export default Zeus;

import '../.defaults/scroll';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import onCallback from '../.internal/callback/onCallback';
import select from '../../fn/select/select';
import merge from '../../helpers/object/merge';
import selectScrollableParent from '../../fn/select/selectScrollableParent';
import Sparkle from '../../Sparkle';

gsap.registerPlugin(ScrollToPlugin);

/*
 * Scroll to a specific Node
 *
 * Powered by GSAP (https://greensock.com)
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node|string} node
 * @param {Object} options
 *
 * @returns {Promise}
 */
export default async function (node, options) {
    const that = this;

    // Options
    options = merge(Sparkle.methods.defaults.scroll, options);

    // If the scrollable parent is `html` or `body`, `window` needs to be used instead
    let scrollableParent = selectScrollableParent(node);
    if (scrollableParent === select('html') || scrollableParent === select('body')) {
        scrollableParent = window;
    }

    // Promise
    await new Promise(resolve => {

        const tween = gsap.to(scrollableParent, {
            duration: options.speed / 1000,
            scrollTo: {
                y: node,
                offsetY: options.offset || 0,
                autoKill: true
            },
            ease: options.ease
        });

        tween.eventCallback('onComplete', () => {
            resolve();
            onCallback.call(that, 'onComplete', options);
        });
    });

    return;
}

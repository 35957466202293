/*
 * VH fix
 *
 * @author Christophe Meade
 * @copyright 2021-present INBW
 */

import addClass from '@sparkle/js/fn/attributes/addClass';
import select from '@sparkle/js/fn/select/select';

// Add class
addClass(select('html'), 'viewport-loaded');

// Trick to solve the 100vh on mobile devices
document.querySelector(':root').style.setProperty('--vh', `${window.innerHeight / 100}px`);
window.addEventListener('resize', () => {
    document.querySelector(':root').style.setProperty('--vh', `${window.innerHeight / 100}px`);
});

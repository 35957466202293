import getData from '../data/getData';

/*
 * Check id the scroll for the specified node is already locked
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {boolean} lock
 * @param {Node} node
 */
export default function (node) {

    node = node || document.body;

    return getData(node, 'bodyLock');
}

import * as Turbo from '@hotwired/turbo';
import addClass from '@sparkle/js/fn/attributes/addClass';
import getAttr from '@sparkle/js/fn/attributes/getAttr';
import hasClass from '@sparkle/js/fn/attributes/hasClass';
import hide from '@sparkle/js/fn/attributes/hide';
import show from '@sparkle/js/fn/attributes/show';
import scrollLock from '@sparkle/js/fn/browser/scrollLock';
import select from '@sparkle/js/fn/select/select';
import selectAll from '@sparkle/js/fn/select/selectAll';
import setProps from '@sparkle/js/helpers/object/setProps';
import className from '@sparkle/js/helpers/utils/className';
import sleep from '@sparkle/js/methods/utils/sleep';
import DomManager from '@sparkle/js/utilities/DomManager';
import EventsManager from '@sparkle/js/utilities/EventsManager';
import Plugins from '@sparkle/js/utilities/Plugins';
import Touchable from '@sparkle/js/utilities/Touchable';
import Tweener from '@sparkle/js/utilities/Tweener';

/*
 * Nitro functionalities
 *
 * @author Christophe Meade
 * @copyright 2021-present INBW
 *
 * @param {Node} el
 * @param {Object} options
 */
const Nitro = function (el, options) {
    const that = this;

    // Options
    setProps(options, {
        selector: Nitro.SELECTOR
    });

    // Modifiers
    that.modifiers = {
        vDisabled: 'v--disabled'
    };

    // Selectors
    that.selectors = {
        viewport: `${options.selector}__viewport`,
        header: `${options.selector}__header`,
        navigation: `${options.selector}__navigation`,
        navigationLink: `${options.selector}__navigation-link`
    };

    // Global variables
    that.el = el;
    that.options = options;
    that.events = new EventsManager();

    // DOM
    that.dom = new DomManager();
    that.dom.set({
        html: () => select('html', document),
        transition: () => select(`${options.selector}__transition`, el),
        transitionBgLeft: () => select(`${options.selector}__transition-bg-left`, that.dom.$('transition')),
        transitionBgRight: () => select(`${options.selector}__transition-bg-right`, that.dom.$('transition'))
    });

    // Init
    that.init();
};

/**
 * Init
 */
Nitro.prototype.init = function () {
    const that = this;

    // Set Drive to true
    Turbo.session.drive = true;

    // Variables
    that.isTransition = false;

    // Fct - Transition -> Show
    const transitionShow = async () => {
        that.isTransition = true;

        // Animate
        await new Tweener({ speed: 800, ease: 'power1.inOut' })
            .add(that.dom.$('transitionBgLeft'), {
                scaleY: { from: 0, to: 1 },
                y: { from: '100%', to: 0 }
            })
            .add(that.dom.$('transitionBgRight'), {
                scaleY: { from: 0, to: 1, speed: 600, delay: 200 },
                y: { from: '100%', to: 0, speed: 600, delay: 200 }
            })
            .onStart(() => {
                show(that.dom.$('transition'));
                that.events.trigger(select(that.selectors.header, that.el), 'handleDisable');
            })
            .play();

        that.events.trigger(document, 'onTransitionOut');
        that.isTransition = false;
        return;
    };

    // Fct - Transition -> Hide
    const transitionHide = async () => {

        // Animate
        await new Tweener({ speed: 800, ease: 'power1.inOut' })
            .add(that.dom.$('transitionBgLeft'), {
                scaleY: { to: 0 },
                y: { to: '-100%' }
            })
            .add(that.dom.$('transitionBgRight'), {
                scaleY: { to: 0, speed: 600, delay: 300 },
                y: { to: '-100%', speed: 600, delay: 300 }
            })
            .play();

        hide(that.dom.$('transition'));
        return;
    };

    // Initial load ?
    that.isInitial = true;

    // Listener - Turbo Visit
    that.events.on(document, 'turbo:visit', () => {
        transitionShow();
    });

    // Listener - Turbo Before Render
    that.events.on(document, 'turbo:before-render', async (e) => {

        // Clear stage
        const clear = () => {

            // Clear all plugins within the HTML node
            Plugins.clear(that.dom.$('html'));

            // It is important to unlock scroll and to scroll to the top of the
            // window before the page is rendered. Otherwise ScrollTrigger can
            // fire unexpectedly
            scrollLock(false);
            window.scrollTo(0, 0);

            // On load the header is disabled
            addClass(select(that.selectors.header, that.el), that.modifiers.vDisabled);
        };

        if (that.isTransition) {
            e.preventDefault();
            that.events.once(document, 'onTransitionOut', () => {
                clear();
                e.detail.resume();
            });
        } else {
            clear();
        }
    });

    // Listener - Turbo Render
    that.events.on(document, 'turbo:render', () => {
        transitionHide();
        that.events.off(document, 'onTransitionOut');
    });

    // Listener - Turbo Load
    that.events.on(document, 'turbo:load', async () => {

        // New page visit throught Turbo
        if (!that.isInitial) {

            // The touchable pluging is attached to the document. Therefore it is
            // not reloaded on a new visit and the events may have already been
            // fired, they need to be fired once agin
            if (hasClass(that.dom.$('html'), className(Touchable.TOUCH))) {
                that.events.trigger(document, 'onTouchable');
            } else if (hasClass(that.dom.$('html'), className(Touchable.NO_TOUCH))) {
                that.events.trigger(document, 'onNoTouchable');
            }

            // On load the header is disabled
            await sleep(200);
            that.events.trigger(select(that.selectors.header, that.el), 'handleEnable');
        }

        // Not initial load anymore
        that.isInitial = false;

        // Deactivate navigation links in order to close the navigation before
        // visiting the clicked link
        const $navigation = select(that.selectors.navigation, that.el);
        const $navigationLinks = selectAll(that.selectors.navigationLink, $navigation);
        that.events.on($navigationLinks, 'click', e => {
            e.preventDefault();

            // Animate
            new Tweener({ speed: 600, ease: 'power1.in' })
                .add(select(that.selectors.viewport, that.el), {
                    opacity: { to: 0 }
                })
                .play();

            const href = getAttr(e.target, 'href');
            that.events.once($navigation, 'onClose', () => {
                Turbo.visit(href);
            });
            that.events.trigger($navigation, 'handleClose');
        });
    });
};

/**
 * Destroy
 */
Nitro.prototype.destroy = function () {
    this.dom.destroy();
    this.events.destroy();
};

/**
 * Constants
 */
Nitro.SELECTOR = '.js-nitro';

export default Nitro;

import attrOption from './.internal/options/attrOption';
import DomManager from './DomManager';
import EventsManager from './EventsManager';
import addClass from '../fn/attributes/addClass';
import removeClass from '../fn/attributes/removeClass';
import setHtml from '../fn/manipulation/setHtml';
import select from '../fn/select/select';
import setProps from '../helpers/object/setProps';

/*
 * Allows to interact with a Carousel
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node} el
 * @param {Object} options
 */
const CarouselNavigation = function (el, options) {
    const that = this;

    options = options || {};

    // Options
    setProps(options, {
        selector: CarouselNavigation.SELECTOR
    });

    // Settings
    options = attrOption(options, el, ['rel'], options.selector);

    // Modifiers
    that.modifiers = {
        vActive: 'v--active'
    };

    // Global variables
    that.el = el;
    that.options = options;
    that.events = new EventsManager();

    // DOM
    that.dom = new DomManager();
    that.dom.set({
        carousel: () => (options.rel ? select(options.rel) : el),
        previous: () => select(`${options.selector}__previous`, el),
        next: () => select(`${options.selector}__next`, el),
        numerationCurrent: () => select(`${options.selector}__numeration-current`, el),
        numerationTotal: () => select(`${options.selector}__numeration-total`, el)
    });

    // Init
    that.init();
};

/**
 * Init
 */
CarouselNavigation.prototype.init = function () {
    const that = this;

    // Listener - On Select
    that.events.on(that.dom.$('carousel'), 'onSelect', e => {

        // Previous
        if (that.dom.$('previous')) {
            if (e.detail.previous) {
                addClass(that.dom.$('previous'), that.modifiers.vActive);
            } else {
                removeClass(that.dom.$('previous'), that.modifiers.vActive);
            }
        }

        // Next
        if (that.dom.$('next')) {
            if (e.detail.next) {
                addClass(that.dom.$('next'), that.modifiers.vActive);
            } else {
                removeClass(that.dom.$('next'), that.modifiers.vActive);
            }
        }

        // Numeration
        if (that.dom.$('numerationCurrent')) {
            setHtml(that.dom.$('numerationCurrent'), `${e.detail.index + 1}`);
        }
        if (that.dom.$('numerationTotal')) {
            setHtml(that.dom.$('numerationTotal'), `${e.detail.slides}`);
        }
    });

    // Listener - Previous
    if (that.dom.$('previous')) {
        that.events.on(that.dom.$('previous'), 'click', e => {
            e.preventDefault();
            that.events.trigger(that.dom.$('carousel'), 'handlePrevious');
        });
    }

    // Listener - Next
    if (that.dom.$('next')) {
        that.events.on(that.dom.$('next'), 'click', e => {
            e.preventDefault();
            that.events.trigger(that.dom.$('carousel'), 'handleNext');
        });
    }
};

/**
 * Destroy
 */
CarouselNavigation.prototype.destroy = function () {
    this.dom.destroy();
    this.evenement.destroy();
};

/**
 * Constants
 */
CarouselNavigation.SELECTOR = '.js-carouselNavigation';

export default CarouselNavigation;
